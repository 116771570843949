console.log("header.js triggered");  



export function header() {

  // var getMasonryItems = function (container, selector) {
  //   var parser = new DOMParser()
  //   var parsedHtml = parser.parseFromString(decodeHTML(container.innerText), 'text/html')
  //   // Both .innerText and .textContent seem to work. Don't use .innerHTML !!!
  
  //   return parsedHtml.querySelectorAll(selector)
  // }
  // var decodeHTML = function (html) {
  //   var textarea = document.createElement('textarea')
  //   textarea.innerHTML = html
  //   return textarea.value
  // }
  
  // var items = getMasonryItems(document.querySelector('noscript'), '[data-item]')


  document.getElementById("main-header").innerHTML = " <div class='nav'>"
            
               + "<div class='col'>"
              // +"      <button id='indexBtn' class=' title='back home'>"
              // + "<p>INDEX</p>"
              // +"      </button>"
              +"      <button id='menuBtn' class=' title='open menu'>"
              + "      <div id='menuBtn-inner'>"
              + "<span><p data-i18n='menu-bt-op'>MENU</p></span>"
              + "<span><p data-i18n='menu-bt-cl'>CLOSE</p></span>"
              + "      </div>"
              // +"      <div id='menuHamb'>"
              // +"      <div class='menuHamb-container'>"
              // +"      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>"
              // +"      <path id='dash1' class='dash' d='M75.42,136.7L132.64,0h-35.56c-10.93,0-13.86,9.44-13.86,9.44L6.21,200h56.32l28.4-77.19-15.51,13.9Z' />"
              // +"      <path id='dash2' class='dash' d='M136.68,0l-28.4,77.19,15.51-13.9-57.22,136.7h35.56c10.93,0,13.86-9.44,13.86-9.44L193,0h-56.32Z' />"
              // +"      <path id='dash3' class='dash' d='M36.87,200L117.44,0h45.37l-80.57,200h-45.37Z'/>"
              // // +"     <polygon id='cross' class='cross' points='163.38 200 122.81 99.3 162.82 0 117.44 0 100.13 42.98 82.81 0 37.44 0 77.44 99.3 36.87 200 82.24 200 100.13 155.61 118.01 200 163.38 200'/>"
              // +"      <path id='cross' class='cross' d='M127.07,95.56l72.93,104.46h-57.09l-44.94-64.31-46.41,64.31H0l72.93-100.74L4.05.02h56.72l41.25,59.11L144.75.02h51.93l-69.61,95.54Z'/>"

              // +"      </svg>"
              // +"      </div>"
              // +"      </div>"
              +"      </button>"
              + "</div>"
              + "<div class='col'>"
              + "<div class='header-contacts'>"
              + "<span class='email-container'></span>"
              + "<span class='phone-container'></span>"
              + "</div>"
              + "</div>"     
              + "<div class='col'>"
              + "<div id='clock'>"
              + "<span><p data-i18n='clock-la'>Los Angeles</span><span class='mobile-hidden'>, CA</p></span>"
              + "<span><p id='date-time'></p></span>"
              + "</div>"
              + "</div>"         
    +" </div>"
    +" <div class='menu backgrTrans'>"
    +"    <div class='menu-wrapper'>"
    +"         <div class='menu-row'>"
    +"                     <div class='col-1-2'>"
    +"                         <div id='workBtn' class='menu-link'><h1 data-i18n='menu-work'>WORK</h1></div>"
    +"                         <a href='/studio' class='menu-link'><h1 data-i18n='menu-studio'>STUDIO</h1></a>"
    +"                         <a href='/contact' class='menu-link'><h1 data-i18n='menu-contact'>CONTACT</h1></a>"
    + "                       <div class='legals'>"
    + "                             <a href='/legal-notice.html'><h6 class='unselectable' data-i18n='legal-notice'>legal notice</h6></a>"
    + "                             <a href='/legal-notice.html#data-protection'><h6 class='unselectable' data-i18n='data-protection'>data protection</h6></a>"
    + "                       </div>"
    + "                       <div class='copyright'>"
    + "                             <h6>©2024 dualthreat.studio // All rights reserved.</h6>"
    + "                       </div>"
    +"                     </div>"
    +"                     <div class='col-1-2'>"
    +"                         <div class='language-switch'>"
    +"                             <ul>"
    +"                                 <li><a href='#' class='lbt' data-lng='en'>ENG</a><span>&nbsp;/&nbsp;</span></li>"
    +"                                  <li><a href='#' class='lbt' data-lng='de'>DEU</a><span>&nbsp;/&nbsp;</span></li>"
    +"                                 <li><a href='#' class='lbt' data-lng='es'>ESP</a></li>"
    +"                             </ul>"
    +"                         </div>"
    // +"                     <div class='indexmenu'>"
    +"                         <div class='theme-switch'>"
    +"                             <input type='checkbox' id='dark-mode-switch' />"
    +"                             <label for='dark-mode-switch'><h5></h5></label>"
    +"                         </div>"
    // +"                     </div>"
    +"                     </div>"
    +"         </div>"
    // + "<div class='copyright'>"
    // + "    <h6 class='unselectable'>©2024 dualthreatinc.com. All rights reserved.</h6>"
    // + "</div>"
    // + "<div class='legals'>"
    // + "    <a href='/legal-notice.html'><h6 class='unselectable'>legal notice</h6></a>"
    // + "    <a href='/legal-notice.html#data-protection'><h6 class='unselectable'>data protection</h6></a>"
    // + "</div>"
    +"     </div><!--END MENUWRAPPER-->"
    +" </div>"



  } 


export function headerCase() {

  // var getMasonryItems = function (container, selector) {
  //   var parser = new DOMParser()
  //   var parsedHtml = parser.parseFromString(decodeHTML(container.innerText), 'text/html')
  //   // Both .innerText and .textContent seem to work. Don't use .innerHTML !!!
  
  //   return parsedHtml.querySelectorAll(selector)
  // }
  // var decodeHTML = function (html) {
  //   var textarea = document.createElement('textarea')
  //   textarea.innerHTML = html
  //   return textarea.value
  // }
  
  // var items = getMasonryItems(document.querySelector('noscript'), '[data-item]')


  document.getElementById("main-header").innerHTML = "<div class='header-container'>"
    +"    <div class='nav'>"
              + "<div id='clock'>"
              + "<span><h6>Los Angeles</span><span class='mobile-hidden'>, CA</h6></span>"
              + "<span><h6 id='date-time'></h6></span>"
              + "</div>"
    // +"     <div class='hamburger' id='hamburger'>"
    // +"         <div class='line2'><div></div></div>"
    // +"         <div class='line2'><div></div></div>"
    // +"      </div>"
    +"      <button id='menuBtn' class=' title='open menu'>"
    +"      <div id='menuHamb'>"
    +"      <div class='menuHamb-container'>"
    +"      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>"
    +"      <path id='dash1' class='dash' d='M75.42,136.7L132.64,0h-35.56c-10.93,0-13.86,9.44-13.86,9.44L6.21,200h56.32l28.4-77.19-15.51,13.9Z' />"
    +"      <path id='dash2' class='dash' d='M136.68,0l-28.4,77.19,15.51-13.9-57.22,136.7h35.56c10.93,0,13.86-9.44,13.86-9.44L193,0h-56.32Z' />"
    +"      <path id='dash3' class='dash' d='M36.87,200L117.44,0h45.37l-80.57,200h-45.37Z'/>"
    // +"     <polygon id='cross' class='cross' points='163.38 200 122.81 99.3 162.82 0 117.44 0 100.13 42.98 82.81 0 37.44 0 77.44 99.3 36.87 200 82.24 200 100.13 155.61 118.01 200 163.38 200'/>"
    +"      <path id='cross' class='cross' d='M127.07,95.56l72.93,104.46h-57.09l-44.94-64.31-46.41,64.31H0l72.93-100.74L4.05.02h56.72l41.25,59.11L144.75.02h51.93l-69.61,95.54Z'/>"
    +"      </svg>"
    +"      </div>"
    +"      </div>"
    +"      </button>"
    +" </div>"
    +" <div class='menu backgrTrans'>"
    +"    <div class='menu-wrapper'>"
    +"         <div class='menu-row'>"
    +"                     <div class='col-1-2' data-swup-preload-all>"
    +"                         <a href='../'><h1>WORKX</h1></a>"
    +"                         <a href='../studio.html'><h1>STUDIO</h1></a>"
    +"                         <a href='../contact.html'><h1>CONTACT</h1></a>"
    +"                         <button id='Button'>STUDIO</button>"
  //  +"                          <button onclick='swup.loadPageWithPreload({ url: \"./about.html\" })'><h1>ABOUT</h1></button>"
    // +"                         <h1>ABOUT</h1>"
    // +"                        <h1>CONTACT</h1>"
    +"                     </div>"
    +"                     <div class='col-1-2'>"
    +"                         <div class='language-switch'>"
    +"                             <ul>"
    +"                                 <li><a href='#' class='lbt' data-lng='en'>ENG</a><span>&nbsp;/&nbsp;</span></li>"
    +"                                  <li><a href='#' class='lbt' data-lng='de'>DEU</a><span>&nbsp;/&nbsp;</span></li>"
    +"                                 <li><a href='#' class='lbt' data-lng='es'>ESP</a></li>"
    +"                             </ul>"
    +"                         </div>"
    // +"                     <div class='indexmenu'>"
    +"                         <div class='theme-switch'>"
    +"                             <input type='checkbox' id='dark-mode-switch' />"
    +"                             <label for='dark-mode-switch'><h5></h5></label>"
    +"                         </div>"
    // +"                     </div>"
    +"                     </div>"
    +"         </div>"
    // + "<div class='copyright'>"
    // + "    <h6 class='unselectable'>©2024 dualthreatinc.com. All rights reserved.</h6>"
    // + "</div>"
    // + "<div class='legals'>"
    // + "    <a href='/legal-notice.html'><h6 class='unselectable'>legal notice</h6></a>"
    // + "    <a href='/legal-notice.html#data-protection'><h6 class='unselectable'>data protection</h6></a>"
    // + "</div>"
    +"     </div><!--END MENUWRAPPER-->"
    +" </div>"

  } 

  

  export function mainlogo() {

    document.getElementById("main-logo").innerHTML = "<div class='logo-container'>"
+ "    <a href='/' id='back-bt' aria-label='click to goo back to homepage'>"
+ "     <div id='logo-container-inner' class='logo-container-inner'>"
+ "     <div class='logo-1'>"
+ "        <svg id='mainLogo' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 390.84 48.28'>"
+ "            <defs>"
+ "            </defs>"
+ "            <path class='dt-logo-home' d='M390.84,3.22c0,1.83-1.44,3.26-3.3,3.26s-3.32-1.44-3.32-3.26,1.47-3.22,3.32-3.22,3.3,1.44,3.3,3.22ZM385.04,3.22c0,1.44,1.06,2.58,2.52,2.58s2.46-1.14,2.46-2.56-1.04-2.59-2.48-2.59-2.5,1.16-2.5,2.58ZM387.02,4.91h-.75V1.69c.29-.06.71-.1,1.24-.1.61,0,.88.1,1.12.24.18.14.31.39.31.71,0,.35-.28.63-.67.75v.04c.31.12.49.35.59.79.1.49.16.69.24.81h-.81c-.1-.12-.16-.41-.26-.79-.06-.35-.26-.51-.67-.51h-.35v1.3ZM387.04,3.09h.35c.41,0,.75-.14.75-.47,0-.29-.22-.49-.69-.49-.2,0-.33.02-.41.04v.92Z'/>"
+ "            <path class='dt-logo-home' d='M65.2.03c15.49,0,27.72,5.62,27.72,23.67s-12.23,23.73-27.72,23.73h-15.63V.03h15.63ZM65.39,9.44h-4.64v28.57h4.64c8.24,0,15.69-1.76,15.69-14.32s-7.45-14.25-15.69-14.25Z'/>"
+ "            <path class='dt-logo-home' d='M118.6,42.52c-2.16,3.46-4.9,5.75-10.33,5.75-9.35,0-12.16-5.3-12.16-14.84V12.25h10.79v18.5c0,5.03.13,9.15,5.36,9.15,4.58,0,6.34-2.61,6.34-9.09V12.25h10.79v35.17h-10.79v-4.9Z'/>"
+ "            <path class='dt-logo-home' d='M154.75,24.09c0-4.12-2.09-5.62-5.95-5.62-3.46,0-5.88,1.77-6.28,5.16h-10.07c.46-6.6,6.86-12.49,17.26-12.49s15.89,4.05,15.69,14.78l-.13,7.65c-.07,4.58.13,10.85.78,13.86h-10.59l-.13-4.25c-2.61,3.73-6.8,5.1-11.83,5.1-6.67,0-12.16-3.92-12.16-10.33,0-8.63,9.74-11.51,23.4-12.49v-1.37ZM142.46,37.16c0,2.29,2.16,3.79,4.97,3.79,4.31,0,7.58-1.83,7.58-8.3v-.85c-7.52.65-12.55,1.63-12.55,5.36Z'/>"
+ "            <path class='dt-logo-home' d='M179.13,47.43h-10.79V.03h10.79v47.4Z'/>"
+ "            <path class='dt-logo-home' d='M202.08,9.84h-15.17V.03h41.65v9.81h-15.23v37.59h-11.24V9.84Z'/>"
+ "            <path class='dt-logo-home' d='M253.13,11.47c9.02,0,11.83,5.3,11.83,14.84v21.12h-10.79v-18.44c0-5.03-.13-9.15-5.36-9.15-4.58,0-6.6,3.07-6.6,9.54v18.04h-10.79V.03h10.79v16.87c1.57-2.62,5.43-5.43,10.92-5.43Z'/>"
+ "            <path class='dt-logo-home' d='M289.74,22.58c-1.18-.13-1.96-.2-2.94-.2-5.16,0-7.91,1.9-7.91,7.39v17.65h-10.79V12.25h10.39v6.67c1.63-4.12,4.58-6.73,9.61-6.93.52,0,1.11,0,1.64.07v10.53Z'/>"
+ "            <path class='dt-logo-home' d='M326.41,36.38c-3.14,8.3-10.46,11.9-17.52,11.9-11.44,0-19.29-7-19.29-18.63s8.56-18.44,18.83-18.44c11.64,0,18.83,8.89,18.11,21.38h-26.09c.33,4.97,4.18,7.32,8.11,7.32s6.67-1.44,7.91-3.53h9.94ZM300.59,26.05h15.23c-.13-4.38-3.33-6.6-7.39-6.6-3.79,0-7.26,1.96-7.85,6.6Z'/>"
+ "            <path class='dt-logo-home' d='M351.12,24.09c0-4.12-2.09-5.62-5.95-5.62-3.46,0-5.88,1.77-6.28,5.16h-10.07c.46-6.6,6.86-12.49,17.26-12.49s15.89,4.05,15.69,14.78l-.13,7.65c-.07,4.58.13,10.85.78,13.86h-10.59l-.13-4.25c-2.61,3.73-6.8,5.1-11.83,5.1-6.67,0-12.16-3.92-12.16-10.33,0-8.63,9.74-11.51,23.4-12.49v-1.37ZM338.83,37.16c0,2.29,2.16,3.79,4.97,3.79,4.31,0,7.58-1.83,7.58-8.3v-.85c-7.52.65-12.55,1.63-12.55,5.36Z'/>"
+ "            <path class='dt-logo-home' d='M367.72,2.64h10.79v9.61h7.71v7.65h-7.71v15.76c0,3.33,1.05,3.99,4.64,3.99h3.07v7.78h-6.86c-8.63,0-11.64-1.63-11.64-9.87v-17.65h-5.56v-7.65h5.56V2.64Z'/>"
+ "            <path class='dt-logo-home' d='M20.08,29.11l-3.68,3.29L29.96,0h-8.43c-2.59,0-3.29,2.24-3.29,2.24L0,47.4h13.35l6.73-18.29Z'/>"
+ "            <path class='dt-logo-home' d='M24.19,18.3l3.68-3.29-13.56,32.4h8.43c2.59,0,3.29-2.24,3.29-2.24L44.27,0h-13.35s-6.73,18.29-6.73,18.29Z'/>"
+ "          </svg>"

+ "</div>"
+ "     <div class='logo-2'>"
+ "   <svg id='backBtn' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 172.5 47.25'>"
+ "   <path class='cls-1' d='M0,.76h19.91c10.02,0,17.45,2.33,17.45,11.84,0,4.16-1.95,7.37-5.42,9.51,4.22,1.51,7.25,4.73,7.25,10.27,0,10.21-7.37,14.05-18.46,14.05H0V.76ZM10.77,19.03h9.51c3.72,0,5.86-1.83,5.86-4.66s-2.14-4.54-5.86-4.54h-9.51v9.2ZM10.77,37.36h10.08c4.6,0,6.93-1.95,6.93-5.17s-2.33-5.17-6.93-5.17h-10.08v10.33Z'/>"
+ "   <path class='cls-1' d='M56.01.76h11.84l17.26,45.68h-11.72l-2.77-8.13h-17.64l-2.71,8.13h-11.47L56.01.76ZM55.94,29.55h11.78l-5.86-17.39-5.92,17.39Z'/>"
+ "   <path class='cls-1' d='M127.39,29.42c-1.64,10.58-10.21,17.83-21.74,17.83-14.24,0-22.87-9.07-22.87-23.69S91.73,0,105.97,0c11.47,0,19.53,7.43,21.11,17.83h-10.58c-1.2-6.24-5.8-8.95-10.9-8.95-6.43,0-11.4,5.36-11.4,14.68s4.98,14.81,11.53,14.81c4.66,0,9.83-2.02,11.09-8.95h10.58Z'/>"
+ "   <path class='cls-1' d='M141.88,18.84L158.45.76h13.17l-16.89,18.21,17.77,27.47h-12.98l-12.35-19.28-5.29,5.8v13.48h-10.77V.76h10.77v18.08Z'/>"
+ "  </svg>"
+ "</div>"
+ "</div>"
+ "</div>"
+ "    </a>   "
  }

  export function mainlogoCase() {

    document.getElementById("main-logo").innerHTML = "<div class='logo-container'>"
+ "    <a href='../' id='back-bt' aria-label='click to go back to homepage'>"
+ "        <svg id='mainLogo' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 390.84 48.28'>"
+ "            <defs>"
+ "            </defs>"
+ "            <path class='dt-logo-home' d='M390.84,3.22c0,1.83-1.44,3.26-3.3,3.26s-3.32-1.44-3.32-3.26,1.47-3.22,3.32-3.22,3.3,1.44,3.3,3.22ZM385.04,3.22c0,1.44,1.06,2.58,2.52,2.58s2.46-1.14,2.46-2.56-1.04-2.59-2.48-2.59-2.5,1.16-2.5,2.58ZM387.02,4.91h-.75V1.69c.29-.06.71-.1,1.24-.1.61,0,.88.1,1.12.24.18.14.31.39.31.71,0,.35-.28.63-.67.75v.04c.31.12.49.35.59.79.1.49.16.69.24.81h-.81c-.1-.12-.16-.41-.26-.79-.06-.35-.26-.51-.67-.51h-.35v1.3ZM387.04,3.09h.35c.41,0,.75-.14.75-.47,0-.29-.22-.49-.69-.49-.2,0-.33.02-.41.04v.92Z'/>"
+ "            <path class='dt-logo-home' d='M65.2.03c15.49,0,27.72,5.62,27.72,23.67s-12.23,23.73-27.72,23.73h-15.63V.03h15.63ZM65.39,9.44h-4.64v28.57h4.64c8.24,0,15.69-1.76,15.69-14.32s-7.45-14.25-15.69-14.25Z'/>"
+ "            <path class='dt-logo-home' d='M118.6,42.52c-2.16,3.46-4.9,5.75-10.33,5.75-9.35,0-12.16-5.3-12.16-14.84V12.25h10.79v18.5c0,5.03.13,9.15,5.36,9.15,4.58,0,6.34-2.61,6.34-9.09V12.25h10.79v35.17h-10.79v-4.9Z'/>"
+ "            <path class='dt-logo-home' d='M154.75,24.09c0-4.12-2.09-5.62-5.95-5.62-3.46,0-5.88,1.77-6.28,5.16h-10.07c.46-6.6,6.86-12.49,17.26-12.49s15.89,4.05,15.69,14.78l-.13,7.65c-.07,4.58.13,10.85.78,13.86h-10.59l-.13-4.25c-2.61,3.73-6.8,5.1-11.83,5.1-6.67,0-12.16-3.92-12.16-10.33,0-8.63,9.74-11.51,23.4-12.49v-1.37ZM142.46,37.16c0,2.29,2.16,3.79,4.97,3.79,4.31,0,7.58-1.83,7.58-8.3v-.85c-7.52.65-12.55,1.63-12.55,5.36Z'/>"
+ "            <path class='dt-logo-home' d='M179.13,47.43h-10.79V.03h10.79v47.4Z'/>"
+ "            <path class='dt-logo-home' d='M202.08,9.84h-15.17V.03h41.65v9.81h-15.23v37.59h-11.24V9.84Z'/>"
+ "            <path class='dt-logo-home' d='M253.13,11.47c9.02,0,11.83,5.3,11.83,14.84v21.12h-10.79v-18.44c0-5.03-.13-9.15-5.36-9.15-4.58,0-6.6,3.07-6.6,9.54v18.04h-10.79V.03h10.79v16.87c1.57-2.62,5.43-5.43,10.92-5.43Z'/>"
+ "            <path class='dt-logo-home' d='M289.74,22.58c-1.18-.13-1.96-.2-2.94-.2-5.16,0-7.91,1.9-7.91,7.39v17.65h-10.79V12.25h10.39v6.67c1.63-4.12,4.58-6.73,9.61-6.93.52,0,1.11,0,1.64.07v10.53Z'/>"
+ "            <path class='dt-logo-home' d='M326.41,36.38c-3.14,8.3-10.46,11.9-17.52,11.9-11.44,0-19.29-7-19.29-18.63s8.56-18.44,18.83-18.44c11.64,0,18.83,8.89,18.11,21.38h-26.09c.33,4.97,4.18,7.32,8.11,7.32s6.67-1.44,7.91-3.53h9.94ZM300.59,26.05h15.23c-.13-4.38-3.33-6.6-7.39-6.6-3.79,0-7.26,1.96-7.85,6.6Z'/>"
+ "            <path class='dt-logo-home' d='M351.12,24.09c0-4.12-2.09-5.62-5.95-5.62-3.46,0-5.88,1.77-6.28,5.16h-10.07c.46-6.6,6.86-12.49,17.26-12.49s15.89,4.05,15.69,14.78l-.13,7.65c-.07,4.58.13,10.85.78,13.86h-10.59l-.13-4.25c-2.61,3.73-6.8,5.1-11.83,5.1-6.67,0-12.16-3.92-12.16-10.33,0-8.63,9.74-11.51,23.4-12.49v-1.37ZM338.83,37.16c0,2.29,2.16,3.79,4.97,3.79,4.31,0,7.58-1.83,7.58-8.3v-.85c-7.52.65-12.55,1.63-12.55,5.36Z'/>"
+ "            <path class='dt-logo-home' d='M367.72,2.64h10.79v9.61h7.71v7.65h-7.71v15.76c0,3.33,1.05,3.99,4.64,3.99h3.07v7.78h-6.86c-8.63,0-11.64-1.63-11.64-9.87v-17.65h-5.56v-7.65h5.56V2.64Z'/>"
+ "            <path class='dt-logo-home' d='M20.08,29.11l-3.68,3.29L29.96,0h-8.43c-2.59,0-3.29,2.24-3.29,2.24L0,47.4h13.35l6.73-18.29Z'/>"
+ "            <path class='dt-logo-home' d='M24.19,18.3l3.68-3.29-13.56,32.4h8.43c2.59,0,3.29-2.24,3.29-2.24L44.27,0h-13.35s-6.73,18.29-6.73,18.29Z'/>"
+ "          </svg>"
+ "    </a>   "
+ "</div>"

  }

//   export function wheelBtn() {

//     document.getElementById("indexWheelBtn").innerHTML = "<div class='logo-container'>"
// + "    <div id='back-bt' aria-label='click to g back to homepage'>"
// + "     <div id='indexWheelBtn-container-inner' class='logo-container-inner'>"
// + "     <div class='logo-1'>"
// + "        <svg id='mainLogo' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 201.47 48'>"
// + "            <defs>"
// + "            </defs>"
// + "            <path class='dt-index' d='M41.85,32.96L47.68.77h10.94l-10.5,46.4h-11.77l-7.04-32.7-6.98,32.7h-11.71L0,.77h11.07l6.34,32.06L23.68.77h11.33l6.85,32.19Z'/>"
// + "            <path class='dt-index' d='M83.58,48c-14.27,0-24.06-9.54-24.06-24S69.31,0,83.58,0s24.06,9.54,24.06,24-9.86,24-24.06,24ZM83.58,38.97c6.78,0,12.48-5.12,12.48-14.97s-5.7-14.98-12.48-14.98-12.48,5.12-12.48,14.98,5.7,14.97,12.48,14.97Z'/>"
// + "            <path class='dt-index' d='M131.65,29.69h-7.42v17.47h-10.94V.77h17.85c12.03,0,21.05,2.82,21.05,14.33,0,6.85-3.78,10.75-9.09,12.8l11.46,19.26h-12.86l-10.05-17.47ZM124.22,9.98v10.5h7.23c5.18,0,9.15-.9,9.15-5.25s-3.97-5.25-9.15-5.25h-7.23Z'/>"
// + "            <path class='dt-index' d='M170.37,19.13L187.2.77h13.38l-17.15,18.49,18.05,27.9h-13.18l-12.54-19.58-5.38,5.89v13.7h-10.94V.77h10.94v18.37Z'/>"


// + "          </svg>"

// + "</div>"
// + "     <div class='logo-2'>"
// + "   <svg id='backBtn' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 206.01 47.25'>"
// + "   <path class='cls-1' d='M44.61,29.42c-1.64,10.58-10.21,17.83-21.74,17.83C8.63,47.25,0,38.18,0,23.56S8.95,0,23.19,0c11.47,0,19.53,7.43,21.11,17.83h-10.58c-1.2-6.24-5.8-8.95-10.9-8.95-6.43,0-11.4,5.36-11.4,14.68s4.98,14.81,11.53,14.81c4.66,0,9.83-2.02,11.09-8.95h10.58Z'/>"
// + "   <path class='cls-1' d='M59.22,36.92h21.93v9.51h-32.7V.76h10.77v36.16Z'/>"
// + "   <path class='cls-1' d='M102.38,47.25c-14.05,0-23.69-9.39-23.69-23.63S88.33,0,102.38,0s23.69,9.39,23.69,23.63-9.7,23.63-23.69,23.63ZM102.38,38.37c6.68,0,12.29-5.04,12.29-14.74s-5.61-14.74-12.29-14.74-12.29,5.04-12.29,14.74,5.61,14.74,12.29,14.74Z'/>"
// + "   <path class='cls-1' d='M146.16,8.63c-4.16,0-7.25,1.58-7.25,4.41,0,2.27,2.14,3.91,5.29,4.6l6.24,1.26c7.37,1.51,16.7,3.78,16.7,13.36s-9.64,15-19.15,15c-11.72,0-19.41-5.86-21.04-15.69h10.71c1.2,4.79,4.98,6.99,10.58,6.99,3.65,0,7.81-1.2,7.81-4.98,0-2.96-3.53-4.41-8.51-5.48l-5.54-1.13c-7.43-1.58-14.18-5.1-14.18-13.29,0-9.32,10.02-13.67,18.96-13.67s17.45,3.91,19.03,13.99h-10.65c-1.07-3.4-4.41-5.36-9.01-5.36Z'/>"
// + "   <path class='cls-1' d='M170.98.76h35.03v9.45h-24.26v8h22.93v9.51h-22.93v9.2h24.26v9.51h-35.03V.76Z'/>"
// + "  </svg>"
// + "</div>"
// + "</div>"
// + "</div>"
// + "    </div>   "
//   }


  export function wheelBtn() {

    document.getElementById("indexWheelBtn").innerHTML = "<div class='logo-container'>"
// + "    <div id='back-bt' aria-label='click to g back to homepage'>"
+ "     <div id='indexWheelBtn-container-inner' class='logo-container-inner'>"
+ "     <div class='logo-1'>"
+ "<span>WORK</span> "
+ "</div>"
+ "     <div class='logo-2'>"
+ "<span>CLOSE</span> "
+ "</div>"
+ "</div>"
// + "</div>"
+ "    </div>   "
  }
 